export const toDate = value => {
    if (value instanceof Date) {
        return formatDate(value);
    }

    if (new Date(value).toString() === 'Invalid Date') {
        return 'Invalid Date';
    }

    return formatDate(new Date(value));
}


// This will be replace with moment later.
const formatDate = date => {
    return new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
    );
}